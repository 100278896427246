import React from "react";
import Filters from "components/crh/reports/incidenceRequest/Filters";
import Modal from "helpers/Modal";
import moment from "moment";
import { getNameIncidence } from "utils/constants";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';

function IncidenceRequestReportView({
	user = {},
	permissionExcel,
	permissionPdf,
	permissionRead,
	onDownloadXlsx,
	onDownloadCSV,
	reportRef,
	reports,
	filterModal,
	filters,
	setFilters,
	showFilterModal,
	hideFilterModal,
}) {

	return (
		<>

			<div className="content container-fluid p-5">
				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de solicitud de incidencias</h1>
						</div>

						<div className="col-sm-auto">
							{
								permissionPdf && (
									<ReactToPrint
										debug={true}
										content={() => reportRef.current}
										trigger={() => (
											<button className="btn bg-danger text-white mx-1">
												<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
												PDF
											</button>
										)}
									/>
								)
							}
							{
								permissionExcel && (
									<>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Excel
										</button>
										<button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
											<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
											CSV
										</button>
									</>
								)
							}
							{
								permissionRead && (
									<button type="button" className="btn btn-primary" onClick={showFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div ref={reportRef}>

					<div className="report-header">

						<div className="d-flex justify-content-center">
							<div className="logo mt-6">
								{
									user?.company?.logo?.url ? (
										<img crossorigin="anonymous" src={user.company.logo.url} height="300" alt="Logo empresa" style={{ borderRadius: "50%" }} />
									) : (
										<img src={NoImage} alt="" style={{ borderRadius: "50%" }} height="300" />
									)
								}
							</div>
						</div>

						<div className="text-center">
							<h1 className="mb-3">{user?.company?.name}</h1>
						</div>

						<div className="text-center">
							<h2 className="mb-3">Reporte de solicitud de incidencias</h2>
						</div>

						<div className="text-center">
							<h4 className="">
								{
									!filters.start_date && !filters.end_date
										? "Todos los registros"
										: moment.utc(filters.start_date).format("DD [de] MMMM [de] YYYY") + " - " + moment.utc(filters.end_date).format("DD [de] MMMM [de] YYYY")
								}
							</h4>
						</div>

					</div>

					<div className="pagebreak"></div>

					<div class="row mt-5">
						<div className="col-md-12">

							<div class="table-responsive datatable-custom report-content" style={{ marginTop: "-5px" }}>
								<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
									<thead style={{ position: "sticky", top: "0", zIndex: 1 }}>
										<tr role="row">
											<th>Folio</th>
											<th>Solicitante</th>
											<th>ID empleado</th>
											<th>Nombre empleado</th>
											<th>Incidencia</th>
											<th>Fecha inicio</th>
											<th>Fecha fin</th>
											<th>Hora inicio</th>
											<th>Hora fin</th>
											<th>Aut. Jefe</th>
											<th>Usuario Jefe</th>
											<th>Aut. RH</th>
											<th>Usuario RH</th>
										</tr>
									</thead>
									<tbody>

										{
											reports.map((incidenceRequest) => (
												<>
													<tr>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.folio}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.applicant?.firstname} {incidenceRequest?.applicant?.lastname}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.employee?.key}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.employee?.firstname} {incidenceRequest?.employee?.dad_lastname} {incidenceRequest?.employee?.mom_lastname}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.incidence?.description ?? ""}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.start_date ? moment.utc(incidenceRequest?.start_date).format("DD/MM/YYYY") : "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{
															function () {
																const incidence = incidenceRequest?.incidence?.type;
																if (incidence === "INSERT_MARKINGS") {
																	return "-";
																} else {
																	return moment.utc(incidenceRequest?.end_date).format("DD/MM/YYYY");
																}
															}()
														}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.start_hour ?? "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.end_hour ?? "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.date_auth_boss ? moment.utc(incidenceRequest?.date_auth_boss).format("DD/MM/YYYY HH:mm:ss") : "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.authorizer_boss?.username ?? "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.date_auth_rh ? moment.utc(incidenceRequest?.date_auth_rh).format("DD/MM/YYYY HH:mm:ss") : "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.authorizer_rh?.username ?? "-"}</td>
													</tr>
													<tr style={{ borderBottom: "1px solid black" }}>
														<td style={{ whiteSpace: "nowrap" }}>Observaciones</td>
														<td colSpan={10} style={{ whiteSpace: "nowrap" }}>{incidenceRequest?.observations ?? "-"}</td>
														<td style={{ whiteSpace: "nowrap" }}>Archivo</td>
														<td style={{ whiteSpace: "nowrap" }}>
															{
																incidenceRequest?.file ? (
																	<a href={incidenceRequest?.file?.url} target="blank">Ver archivo</a>
																) : ("-")
															}
														</td>
													</tr>
												</>

											))
										}

									</tbody>
								</table>
							</div>

						</div>

					</div>

					<h5 class="mt-2">Total: {reports.length} registros</h5>
				</div>

			</div>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideFilterModal}
			>
				<Filters
					hideModal={hideFilterModal}
					filters={filters}
					setFilters={setFilters}
					refresh={Math.random()}
				/>
			</Modal>

		</>
	)

}

export default IncidenceRequestReportView;