import React from "react";
import DeviceEmployeeList from "components/crh/deviceEmployees/List";
import Modal from "helpers/Modal";

function DeviceEmployeesView({
	isSyncLoading,
	countRef,
	edit,
	excel,
	deviceEmployeeListRef,
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addDeviceEmployeeModal,
	reloadDevices,
	setReloadDevices,
	theme,
	onDownloadXlsx,
	onSync,
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Lectores - Empleados</h1>
						</div>
						<div className="col-sm-auto">
							<button 
								disabled={isSyncLoading}
								type="button" 
								className="btn bg-warning text-black mx-1" 
								onClick={onSync}
							>
								{
									isSyncLoading 
										? <>
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												<span className="visually-hidden">Loading...</span>
											</> 
										: <>
											<i className="bi bi-arrow-repeat" style={{ marginRight: "7px" }}></i>
											Sincronizar
										</>
								}
							</button>
							{excel && (
								<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
									<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
									Excel
								</button>
							)}
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 d-flex justify-content-between">

							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar dispositvo"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>

							<div className="d-flex justify-content-right mx-3">
								<span className="pt-2 mx-1">Total de dispositivos: </span>
								<span className="pt-2 border-0" ref={countRef} />
							</div>

						</div>
					</div>

					<DeviceEmployeeList
						countRef={countRef}
						ref={deviceEmployeeListRef}
						theme={theme}
						search={search}
						addDeviceEmployeeModal={addDeviceEmployeeModal}
						reloadDevices={reloadDevices}
						setReloadDevices={setReloadDevices}
					/>

				</div>

			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

		</>
	);
}

export default DeviceEmployeesView;