import React from "react";
import Modal from "helpers/Modal";
import Filters from "components/crh/visits/attendanceLogs/Filters";
import moment from "moment";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';

function AttendanceView({
  user,
  permissionExcel,
  permissionPdf,
  permissionRead,
  setLoading,
  searchFilters,
  onChangeSearch,
  handleKeyUp,
  onDownloadCSV,
  onDownloadXlsx,
  onDownloadXlsxColumns,
  reportRef,
  attendanceLogs,
  filters,
  setFilters,
  hideModal,
  showModal,
  setFilterModal,
  filterModal,
  refreshAttendanceLogs,
}) {
  return (
    <>
      <div className="content container-fluid p-5">

        <div className="page-header mb-3">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title">Reporte de visitas</h1>
            </div>
            <div className="col-sm-auto">

              {
                permissionPdf && (
                  <ReactToPrint
                    debug={true}
                    content={() => reportRef.current}
                    trigger={() => (
                      <button className="btn bg-danger text-white mx-1">
                        <i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
                        PDF
                      </button>
                    )}
                  />
                )
              }

              {
                permissionExcel && (
                  <>
                    <button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
                      <i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
                      Excel
                    </button>
                    <button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsxColumns}>
                      <i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
                      Excel Columnas
                    </button>
                    <button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
                      <i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
                      CSV
                    </button>
                  </>
                )
              }

              {
                permissionRead && (
                  <button type="button" className="btn btn-primary" onClick={showModal}>
                    <i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
                    Filtros de búsqueda
                  </button>
                )
              }

            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header card-header-content-md-between">
            <div class="mb-2 mb-md-0">
              <div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
                <div class="input-group-prepend input-group-text" >
                  <i class="bi-search"></i>
                </div>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Buscar empleado"
                  onChange={onChangeSearch}
                  onKeyUp={handleKeyUp}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 attendance-report" ref={reportRef}>

            <div className="report-header">

              <div className="d-flex justify-content-center">
                <div className="logo mt-6">
                  {
                    user?.company?.logo?.url ? (
                      <img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
                    ) : (
                      <img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
                    )
                  }
                </div>
              </div>

              <div className="text-center">
                <h1 className="mb-3">{user?.company?.name}</h1>
              </div>

              <div className="text-center">
                <h2 className="mb-3">Reporte de visitas</h2>
              </div>

              <div className="text-center">
                <h4 className="">
                  {
                    !filters.start_date && !filters.end_date
                      ? "Todos los registros"
                      : filters.start_date + " - " + filters.end_date
                  }
                </h4>
              </div>

            </div>

            <div className="pagebreak"></div>

            {
              attendanceLogs && attendanceLogs.map((report) => (
                report.visitAttendanceLogs.length > 0 ? (
                  <>
                    <div class="table-responsive datatable-custom position-relative report-content">
                      <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">

                        <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                          <thead>
                            <tr role="row">
                              <th style={{ width: "5%" }}>ID</th>
                              <th style={{ width: "35%" }}>Nombre</th>
                              <th style={{ width: "20%" }}>A quién visita</th>
                              <th style={{ width: "20%" }}>Departamento</th>
                              <th style={{ width: "20%" }}>Perfil</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{report.visitRequest?.folio}</td>
                              <td>{report.visitRequest?.name}</td>
                              <td>{report.visitRequest?.host}</td>
                              <td>{report.visitRequest?.department?.abbreviation}</td>
                              <td>{report.visitRequest?.visit_profile?.abbreviation}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="mx-3">
                          <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                            <thead>
                              <tr role="row" className="bg-dark">
                                <th>Fecha</th>
                                <th>Descripción</th>
                                <th>Ubicación</th>
                                <th>Hora</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                report.visitAttendanceLogs.map((attendanceLog) => (
                                  <tr>
                                    <td>{attendanceLog.date}</td>
                                    <td>{attendanceLog.device.description}</td>
                                    <td>{attendanceLog.device.location}</td>
                                    <td>{attendanceLog.time}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                    {/* <div className="pagebreak"></div> */}

                    <hr />
                  </>
                ) : null
              ))
            }
          </div>
          <div className="col-md-4">
            <div class="card mb-3 mb-md-5">
              <div class="card-header">
                <h5 class="card-header-title">Detalles de la búsqueda</h5>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-md-6 d-flex align-items-center">
                    <span className="mx-2 fw-bold">Fecha de búsqueda: </span>
                  </div>
                  <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.start_date && filters.start_date !== "" ? moment(filters.start_date).format("DD/MM/YYYY") : " - "}</span>
                  </div>
                  <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.end_date && filters.end_date !== "" ? moment(filters.end_date).format("DD/MM/YYYY") : " - "}</span>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 fw-bold">Rango de horas: </span>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.start_hour && filters.start_hour !== "" ? filters.start_hour : " - "}</span>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.end_hour && filters.end_hour !== "" ? filters.end_hour : " - "}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal
        title={"Filtros de búsqueda"}
        isVisible={filterModal}
        setIsVisible={hideModal}
      >
        <Filters
          hideModal={hideModal}
          filters={filters}
          setFilters={setFilters}
          setLoading={setLoading}
        />
      </Modal>

    </>
  );
}

export default AttendanceView;