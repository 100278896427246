import React from "react";
import Filters from "components/crh/employees/Filters";
import EmployeesList from "components/crh/employees/List";
import Modal from "helpers/Modal";
import ReactToPrint from "react-to-print";
import BulkLoad from "components/crh/employees/BulkLoad";
import NoImage from 'resources/images/no-avatar.png';

function EmployeesView({
	company,
	excel,
	read,
	load,
	create,
	user,
	reportRef,
	onDownloadCSV,
	onDownloadXlsx,
	employeeListRef,
	countRef,
	showFilterModal,
	hideFilterModal,
	filterModal,
	setFilters,
	searchFilters,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalContent,
	addEmployeeModal,
	addEditEmployeeModal,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContentEdit,
	theme,
	isVisibleLoad,
	setIsVisibleLoad,
	addBulkLoadModal,
	isVisibleLeave,
	setIsVisibleLeave,
	modalContentLeave,
	addLeaveEmployeeModal,
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Empleados</h1>
						</div>

						<div className="col-sm-auto">

							{/* <ReactToPrint
								debug={true}
								content={() => reportRef.current}
								trigger={() => (
									<button className="btn bg-danger text-white mx-1">
										<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
										PDF
									</button>
								)}
							/> */}

							{
								excel && (
									<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
										<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
										Excel
									</button>
								)
							}

							{/* <button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
								<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
								CSV
							</button> */}

							{
								read && (
									<button type="button" className="btn btn-primary mx-1" onClick={showFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}

							{
								load && (
									<button type="button" className="btn btn-primary" onClick={addBulkLoadModal} style={{ marginRight: "10px" }}>
										<i className="bi bi-cloud-arrow-up-fill" style={{ marginRight: "7px" }}></i>
										Carga masiva
									</button>
								)
							}

							{
								create && (
									<button type="button" className="btn btn-primary mx-1" onClick={addEmployeeModal} style={{ marginRight: "10px" }}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Agregar empleado
									</button>
								)
							}

						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 d-flex justify-content-between">

							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar empleado"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>

							</div>

							<div className="d-flex justify-content-right mx-3">
								<span className="pt-2 mx-1">Total de empleados: </span>
								<span className="pt-2 border-0" ref={countRef} />
							</div>

						</div>
					</div>

					<div ref={reportRef}>

						<div className="report-header">

							<div className="d-flex justify-content-center">
								<div className="logo mt-6">
									{
										user?.company?.logo?.url ? (
											<img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
										) : (
											<img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
										)
									}
								</div>
							</div>

							<div className="text-center">
								<h1 className="mb-3">{user?.company?.name}</h1>
							</div>

							<div className="text-center">
								<h2 className="mb-3">Reporte de marcajes</h2>
							</div>

						</div>

						<div className="pagebreak"></div>

						<EmployeesList
							company={company}
							ref={employeeListRef}
							countRef={countRef}
							theme={theme}
							refresh={Math.random()}
							searchFilters={searchFilters}
							addEditEmployeeModal={addEditEmployeeModal}
							addLeaveEmployeeModal={addLeaveEmployeeModal}
						/>

					</div>

				</div>

			</div>

			<Modal
				title="Empleado"
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title="Editar empleado"
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideFilterModal}
			>
				<Filters
					// setLoading={setLoading}
					company={company}
					hideModal={hideFilterModal}
					filters={searchFilters}
					setFilters={setFilters}
				/>
			</Modal>

			<Modal
				title={"Carga masiva"}
				isVisible={isVisibleLoad}
				setIsVisible={setIsVisibleLoad}
			>
				<BulkLoad company={company} setIsVisible={setIsVisibleLoad} />
			</Modal>

			<Modal
				title={"Dar de baja empleado"}
				isVisible={isVisibleLeave}
				setIsVisible={setIsVisibleLeave}
			>
				{modalContentLeave}
			</Modal>

		</>
	);
}

export default EmployeesView;