import React from "react";
import EmployeeDeviceList from "components/crh/employeeDevices/List";
import Modal from "helpers/Modal";

function EmployeeDevicesView({
	countRef,
	employeeDeviceRef,
	onDownloadXlsx,
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addEmployeeDeviceModal,
	reloadEmployees,
	setReloadEmployees,
	theme,
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Empleados - Lectores</h1>
						</div>
						<div className="col-sm-auto">
							<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
								<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
								Excel
							</button>
						</div>
					</div>
				</div>
				
				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 d-flex justify-content-between">

							<div class="input-group input-group-merge input-group-flush" style={{width: "50%"}}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input 
									type="search" 
									class="form-control" 
									placeholder="Buscar empleado" 
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>

							<div className="d-flex justify-content-right mx-3">
								<span className="pt-2 mx-1">Total de empleados: </span>
								<span className="pt-2 border-0" ref={countRef} />
							</div>

						</div>
					</div>

					<EmployeeDeviceList
						countRef={countRef}
						ref={employeeDeviceRef}
						theme={theme}
						search={search}
						addEmployeeDeviceModal={addEmployeeDeviceModal}
						reloadEmployees={reloadEmployees}
						setReloadEmployees={setReloadEmployees}
					/>

				</div>

			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

		</>
	);
}

export default EmployeeDevicesView;