import React from "react";
import PlanningList from "components/crh/plannings/List";
import Modal from "helpers/Modal";
import BulkLoad from "components/crh/plannings/BulkLoad";

function EmployeesView({
	excel,
  read,
  load,
  create,
  user,
  reportRef,
  onDownloadXlsx,
  listRef,
  showFilterModal,
  hideFilterModal,
  filterModal,
  setFilterModal,
  setFilters,
  searchFilters,
  onChangeSearch,
  handleKeyUp,
  isVisible,
  setIsVisible,
  modalContent,
  addPlanningModal,
  addEditPlanningModal,
  isVisibleEdit,
  setIsVisibleEdit,
  modalContentEdit,
	isVisibleDetails,
	setIsVisibleDetails,
	modalContentDetails,
	addDetailsModal,
  theme,
  isVisibleLoad,
  setIsVisibleLoad,
  addBulkLoadModal,
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Planeación semanal</h1>
						</div>

						<div className="col-sm-auto">

							{/* <ReactToPrint
								debug={true}
								content={() => reportRef.current}
								trigger={() => (
									<button className="btn bg-danger text-white mx-1">
										<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
										PDF
									</button>
								)}
							/> */}

							{/* {
								excel && (
									<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
										<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
										Excel
									</button>
								)
							} */}

							{
								load && (
									<button type="button" className="btn btn-primary" onClick={addBulkLoadModal} style={{ marginRight: "10px" }}>
										<i className="bi bi-cloud-arrow-up-fill" style={{ marginRight: "7px" }}></i>
										Carga masiva
									</button>
								)
							}

							{
								create && (
									<button type="button" className="btn btn-primary mx-1" onClick={addPlanningModal} style={{ marginRight: "10px" }}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Agregar planeación
									</button>
								)
							}

						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 d-flex justify-content-between">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar empleado"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>

					<div ref={reportRef}>
						<PlanningList
							ref={listRef}
							theme={theme}
							refresh={Math.random()}
							searchFilters={searchFilters}
							onDownloadXlsx={onDownloadXlsx}
							addEditPlanningModal={addEditPlanningModal}
							addDetailsModal={addDetailsModal}
						/>
					</div>

				</div>

			</div>

			<Modal
				title="Planeación"
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title="Editar planeación"
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

			<Modal
				title="Detalles de la planeación"
				isVisible={isVisibleDetails}
				setIsVisible={setIsVisibleDetails}
			>
				{modalContentDetails}
			</Modal>

			<Modal
				title={"Carga masiva"}
				isVisible={isVisibleLoad}
				setIsVisible={setIsVisibleLoad}
			>
				<BulkLoad setIsVisible={setIsVisibleLoad} />
			</Modal>

		</>
	);
}

export default EmployeesView;