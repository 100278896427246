import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import { toast } from "react-toastify"
import { useGetCall, useDelete, fetchPost } from "utils/api"
import Loading from "helpers/Loading"
import { formatISODate } from "utils/date"
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext"
import View from "components/crh/employees/List.view"

const EmployeesList = forwardRef((props, ref) => {

	const {
		company,
		theme,
		countRef,
		refresh,
		searchFilters,
		addEditEmployeeModal,
		addLeaveEmployeeModal
	} = props

	const userType = sessionStorage.getItem("type")
	const { loading, setLoading } = useContext(LoadingContext);
	const [refreshValue, setRefreshValue] = useState(null)
	const [employees, setEmployees] = useState([])

	if (countRef != null && countRef.current != null)
		countRef.current.innerHTML = employees.length

	if (refresh !== refreshValue)
		setRefreshValue(refresh)

	const [callEmployees, reqEmployees] = useGetCall("/employees", {
		onCompleted: (response) => {
			setLoading(false);
			setEmployees(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "employees", "empleados", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/employees", {
		onCompleted: () => {
			if (!toast.isActive("delete-device"))
				toast.success("Empleado eliminado", { toastId: "delete-employees" })
			callEmployees({ ...searchFilters, register_crh: true, company });
			setRefreshValue(Math.random())
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "employees", "empleados", "delete")
		}
	})


	useImperativeHandle(ref, () => ({
		generateCells() {
			return generateCells()
		}
	}))

	const generateCells = () => {

		let csv = [
			[
				"ID Empleado",
				"ID Lector",
				"Nombre",
				"Apellido paterno",
				"Apellido materno",
				"CURP",
				"RFC",
				"NSS",
				"Salario",
				"Fecha de ingreso",
				"Fecha inicia turno",
				"Correo",
				"Sucursal",
				"Departamento",
				"Puesto",
				"Grupo",
				"Contrato",
				"Tipo de nómina",
				"Jefe directo",
				"Área",
				"Subárea",
				"Turno",
				"Centro de costo",
				"Empresa de nómina",
				"Status",
				"Fecha de baja",
				"Motivo de baja",
				"Comentarios de baja",
			]
		];

		employees.forEach((employee) => {
			csv.push([
				employee.key,
				employee.pin,
				employee.firstname,
				employee.dad_lastname,
				employee.mom_lastname ? employee.mom_lastname : "",
				employee.curp ? employee.curp : "",
				employee.rfc ? employee.rfc : "",
				employee.nss ? employee.nss : "",
				employee.salary ? employee.salary : "",
				employee.admission ? formatISODate(employee.admission) : "",
				employee.start_shift ? formatISODate(employee.start_shift) : "",
				employee.email ? employee.email : "",
				employee.office ? employee.office.description : "",
				employee.department ? employee.department.description : "",
				employee.job ? employee.job.description : "",
				employee.group ? employee.group.description : "",
				employee.contract ? employee.contract.description : "",
				employee.payroll ? employee.payroll.description : "",
				employee.boss ? employee.boss.description : "",
				employee.area ? employee.area.description : "",
				employee.subarea ? employee.subarea.description : "",
				employee.shift ? employee.shift.description : "",
				employee.cost_center ? employee.cost_center.description : "",
				employee.company_payroll ? employee.company_payroll : "",
				employee.status == "ACTIVE" ? "Activo" : "Inactivo",
				employee.leave ? formatISODate(employee.leave) : "",
				employee.reason_leave?.description??"",
				employee.leave_comments??""
			])
		})

		return csv;

	}

	const onDelete = (id) => {
		fetchPost("/employees/check_permission", { type: "delete" }).then((response) => {
			if(response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el empleado?")) {
						callDelete({ id: id })
					}
				} else {
					if (!toast.isActive("toast-delete-empleado-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar empleados", { toastId: "toast-delete-empleado-unauthorized" })
					}
				}
			}
		})
	}

	useEffect(() => {
		setLoading(true);
		callEmployees({ ...searchFilters, register_crh: true, company });
	}, [refreshValue, searchFilters])

	const deleteLoading = reqDelete.isLoading

	const onEdit = (id) => addEditEmployeeModal(id)
	if (loading) return <Loading />

	return (
		<View
			deleteLoading={deleteLoading}
			employees={employees}
			onEdit={onEdit}
			onDelete={onDelete}
			theme={theme}
			userType={userType}
			addLeaveEmployeeModal={addLeaveEmployeeModal}
		/>
	)

})

export default EmployeesList