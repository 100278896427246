import React, { useState, useEffect, useContext, useRef } from "react";
import Filters from "components/crh/extraTimeRequests/Filters";
import { toast } from "react-toastify";
import { getUser } from "api/users";
import { usePut, usePost, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message"
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/extraTimeRequests/List.view";
import { getLocaleUTC } from "utils/date";

function List({
	refresh,
	company,
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const reportRef = useRef(null);
	const [extraTimeRequests, setExtraTimeRequests] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshUpdate, setRefreshUpdate] = useState(null);
	const [user, setUser] = useState({});
	const [selected, setSelected] = useState({});
	const [editExtraTimeRequest, setEditExtraTimeRequest] = useState([]);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const id = sessionStorage.getItem("id");

	const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [filters, setFilters] = useState({});

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callIncidenceRequests, reqIncidenceRequests] = useGetCall("/extra_time_requests", {
		onCompleted: (response) => {
			setLoading(false);
			setExtraTimeRequests(response.data.map((request) => ({ 
					...request, 
					extraTimeRequests: request.extraTimeRequests.map((extraTimeRequest) => ({
						...extraTimeRequest,
						extra_time_auth: extraTimeRequest.extra_time_auth??extraTimeRequest.incidences_report?.extra_time_round??extraTimeRequest.incidences_report?.extra_time
					})
			)})))
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "extra_time", "tiempos extras", "read");
		}
	})

	const [callAuth, reqAuth] = usePut("/extra_time_requests/auth", {
		onCompleted: () => {

			if (!toast.isActive("extra_time-updated"))
				toast.success("Tiempo extra autorizado correctamente", { toastId: "extra_time-deleted" });

			setEditExtraTimeRequest([]);
			setRefreshUpdate(Math.random())

		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403 && !toast.isActive("extra_time-edit-error"))
				toast.error("Error, no tienes los permisos para autorizar tiempos extras", { toastId: "extra_time-edit-error" });
			else if (!toast.isActive("extra_time-auth-error"))
				toast.error("Error al autorizar el tiempo extra", { toastId: "extra_time-auth-error" });

		}
	})

	const [callAuthGroup, reqAuthGroup] = usePost("/extra_time_requests/auth", {
		onCompleted: () => {
			if (!toast.isActive("extra_time-updated"))
				toast.success("Tiempos extras autorizados correctamente", { toastId: "extra_time-deleted" });

			setEditExtraTimeRequest([]);
			setRefreshUpdate(Math.random());
			
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403 && !toast.isActive("extra_time-edit-error"))
				toast.error("Error, no tienes los permisos para autorizar tiempos extras", { toastId: "extra_time-edit-error" });
			else if (!toast.isActive("extra_time-auth-error"))
				toast.error("Error al autorizar el tiempo extra", { toastId: "extra_time-auth-error" });
		}
	})

	const [callCancel, reqCancel] = usePut("/extra_time_requests/cancel", {
		onCompleted: () => {
			if (!toast.isActive("extra_time-cancel"))
				toast.success("Tiempo extra cancelado correctamente", { toastId: "extra_time-cancel" });

			setRefreshUpdate(Math.random())
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403 && !toast.isActive("extra_time-cancel-error"))
				toast.error("Error, no tienes los permisos para cancelar tiempos extras", { toastId: "extra_time-cancel-error" });
			else if (!toast.isActive("extra_time-cancel-error"))
				toast.error("Error al cancelar el tiempo extra", { toastId: "extra_time-cancel-error" });

		}
	})

	const onSelectCheckbox = (employeeId, id) => {

		const _selected = selected[employeeId] ?? [];
		const index = _selected.findIndex((item) => item === id);
		const newSelected = [..._selected];

		if (index !== -1) {
			newSelected.splice(index, 1);
		} else {
			newSelected.push(id)
		}

		setSelected({ ...selected, [employeeId]: newSelected });

	}

	useEffect(() => {
		setLoading(true);
		callIncidenceRequests(filters);
		getUser(id).then((response) => {
			if (response && response.data) {
				const user = response.data;
				setUser(user)
			}
		})
	}, [refreshValue, refreshUpdate, id, filters]);

	const onAuth = (extraTimeRequest) => callAuth({ 
		id: extraTimeRequest.id,
		extra_time_auth: extraTimeRequest.extra_time_auth,
		date: getLocaleUTC() 
	});

	const onAuthGroup = () => {

		if(selected.length === 0) {
			
			if (!toast.isActive("extra_time-unauthorized"))
				toast.error("Error, no se ha seleccionado ningún tiempo extra", { toastId: "extra_time-unauthorized" })

			return;

		}

		const final = [];
		Object.entries(selected).map(([employeeId, ids]) => ids.forEach(id => {
			
			let extra_time_auth = null;
			extraTimeRequests.forEach((request) => 
				request.extraTimeRequests.forEach((extraTimeRequest) => {
					if(extra_time_auth !== null) return;
					if(extraTimeRequest.id === id)
						extra_time_auth = extraTimeRequest.extra_time_auth;
				}))

			final.push({ id, extra_time_auth })

		}));

		callAuthGroup({ 
				data: final,
				date: getLocaleUTC() 
		});

	}

	const onCancel = (id) => {
		fetchPost("/permissions/check", { table: "AUTH_EXTRA_TIME", permission: "edit" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Está seguro que desea cancelar el tiempo extra?")) {
						callCancel({ id })
					}
				} else {
					if (!toast.isActive("extra_time-unauthorized")) {
						toast.error("Error, no tienes los permisos para cancelar tiempos extras", { toastId: "extra_time-incidences-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {

		setExtraTimeRequests(extraTimeRequests.map((request) => {
			return {
				...request,
				extraTimeRequests: request.extraTimeRequests.map((extraTimeRequest) => {
					if (extraTimeRequest.id === id) {
						return {
							...extraTimeRequest,
							edit: true
						}
					}
					return extraTimeRequest;
				})
			}
		}));

		if(editExtraTimeRequest.includes(id))
			setEditExtraTimeRequest(editExtraTimeRequest.filter((item) => item !== id));
		else
			setEditExtraTimeRequest([...editExtraTimeRequest, id]);

	}

	const addModal = () => {
    fetchPost("/permissions/check", { table: "AUTH_EXTRA_TIME", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Filtros de búsqueda");
          setModalContent(
            <Filters
              company={company}
              setIsVisible={setIsVisible}
              refresh={Math.random()}
              setFilters={setFilters}
            />
          );
        } else {
          if (!toast.isActive("toast-read-extra-time-unauthorized")) {
            toast.error("Error, no tienes los permisos para consultar los tiempos extras", { toastId: "toast-read-extra-time-unauthorized" })
          }
        }
      }
    })
  }

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...filters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...filters, search: event.target.value });
		}
	}

	useEffect(() => {
			fetchPost("/permissions/check", { table: "AUTH_EXTRA_TIME", permission: "pdf" }).then((response) => {
				if (response.data) setPermissionPdf(response.data.isAllowed);
			});
		}, []);

	return (
		<View
			reportRef={reportRef}
			filters={filters}
			permissionPdf={permissionPdf}
			addModal={addModal}
			selected={selected}
			setSelected={setSelected}
			extraTimeRequests={extraTimeRequests}
			user={user}
			onAuth={onAuth}
			onEdit={onEdit}
			onCancel={onCancel}
			onAuthGroup={onAuthGroup}
			onSelectCheckbox={onSelectCheckbox}
			editExtraTimeRequest={editExtraTimeRequest}
			setExtraTimeRequests={setExtraTimeRequests}
			onChangeSearch={onChangeSearch}
			handleKeyUp={handleKeyUp}
			modalTitle={modalTitle}
			isVisible={isVisible}
			setIsVisible={setIsVisible}
			modalContent={modalContent}
		/>
	);
}

export default List;