import React, { useState, useEffect, useRef } from "react";
import { usePost, usePut, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { toast } from "react-toastify";
import View from "components/crh/timetables/Form.view";

const weekDayToNumber = (weekDay) => {
    if (weekDay === "MONDAY") return 0;
    if (weekDay === "TUESDAY") return 1;
    if (weekDay === "WEDNESDAY") return 2;
    if (weekDay === "THURSDAY") return 3;
    if (weekDay === "FRIDAY") return 4;
    if (weekDay === "SATURDAY") return 5;
    if (weekDay === "SUNDAY") return 6;
}

const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY"
]

function FormSet({ shiftId, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [shift, setShift] = useState({});
    const [schedules, setSchedules] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);
    const [weeks, setWeeks] = useState([])
    const [weeksNum, setWeeksNum] = useState(0);
    const [maxWeek, setMaxWeek] = useState(0); 

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callTimetables, reqTimetables] = useGetCall(`/timetables/shift_schedule/${shiftId}`, {
        onCompleted: (response) => {

            const data = response.data;

            if (data.length === 0) {
                setWeeksNum(1);
                setMaxWeek(0);
                const newWeeks = days.map((day) => {
                    return {
                        num: 1,
                        day: day,
                        timteable: null,
                        schedule: null
                    }
                })
                setWeeks(newWeeks);
            } else {
                const valuesWeekNum = data.map((item) => item.week_num)
                setMaxWeek(Math.max(...valuesWeekNum));
                setWeeksNum(Math.max(...valuesWeekNum));
                const newWeeks = data.map((item) => {
                    return {
                        num: item.week_num,
                        day: item.day,
                        timteable: item.id,
                        schedule: item.schedule
                    }
                })
                setWeeks(newWeeks)
            }

        },
        onError: (error) => {
            if (error.status === 403) {
                if (!toast.isActive("toast-read-timetables-unauthorized")) {
                    toast.error("Error, no tienes los permisos para consultar los turnos/horarios", { toastId: "toast-read-timetables-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-timetables-error")) {
                    toast.error("Error al obtener los turnos/horarios", { toastId: "toast-timetables-error" })
                }
            }
        }
    })

    const [callShift, reqShift] = useGetCall(`/shifts/${shiftId}`, {
        onCompleted: (response) => {
            const data = response.data;
            setShift(data);
        },
        onError: (error) => {
            showResponseMessage(error.status, "shift", "turnos", "read")
        }
    })

    const [callSchedules, reqSchedules] = useGetCall("/schedules", {
        onCompleted: (response) => {
            setSchedules(response.data)
        },
        onError: (error) => {
            if (error.status === 403) {
                if (!toast.isActive("toast-read-schedules-unauthorized")) {
                    toast.error("Error, no tienes los permisos para consultar los horarios", { toastId: "toast-read-schedules-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-jobs-error")) {
                    toast.error("Error al obtener los horarios", { toastId: "toast-schedules-error" })
                }
            }
        }
    })

    const [callSave, reqSave] = usePost("/timetables/shift_schedule", {
        onCompleted: () => {
            if (!toast.isActive("timetable-updated")) {
                toast.success("Turno - Horario actualizado", { toastId: "timetable-updated" });
            }

            formikRef.current.setValues({});
            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err)

            if (err.status === 403) {
                if (!toast.isActive("toast-edit-timetable-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear o editar turnos/horarios", { toastId: "toast-edit-timetable-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-timetable-error")) {
                    toast.error("Error interno del servidor, por favor intente mas tarde", { toastId: "toast-timetable-error" })
                }
            }
        }
    })

    const [callReset, reqReset] = usePut("/timetables/reset", {
        onCompleted: () => {
            if (!toast.isActive("timetables-reseted")) {
                toast.success("Valores reestablecidos exitosamente", { toastId: "timetables-reseted" })
            }
            formikRef.current.setValues({});
            setIsVisible(false);
        },
        onError: (error) => {
            console.error(error);
            if (error.status === 403) {
                if (!toast.isActive("toast-edit-timetable-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear o editar turnos/horarios", { toastId: "toast-edit-timetable-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-timetable-error")) {
                    toast.error("Error al resetear los valores, por favor intente mas tarde", { toastId: "toast-timetable-error" })
                }
            }
        }
    })

    useEffect(() => {
        callTimetables();
        callSchedules();
        callShift();
    }, [shiftId, refreshValue, shiftId])

    const onSubmit = (values) => {

        if (shift.rotating && weeks.length === 0){
            toast.error("El turno rotativo debe tener minimo una semana para repetir");
            return;
        }

        weeks.forEach((day) => {
            if (day.schedule === null || day.schedule === undefined) {
                toast.error("Debe seleccionar un horario para cada día");
                return;
            }
        })

        const payload = {
            data: weeks.map((item) => {
                return {
                    id: item.timteable,
                    day: item.day,
                    week_num: item.num,
                    schedule_id: item.schedule.id
                }
            }),
            shift_id: shiftId,
        }

        callSave(payload)

    }

    const handleOnChange = (weekDay, weekNum, event) => {
        const schedule = schedules.find((item) => item.id === parseInt(event.target.value));
        let newWeeks = [...weeks];
        const index = newWeeks.findIndex((item) => item.num === weekNum && item.day === weekDay);

        if (index !== -1) {
            newWeeks[index].schedule = schedule;
            setWeeks(newWeeks);
        }
    }

    const onCancel = () => {
        setWeeks([]);
        setWeeksNum(0);
        formikRef.current.setValues({});
        setIsVisible(false);
    }

    const onReset = () => {
        if (window.confirm("¿Está seguro que desea reestablecer los valores?")) {
            callReset({ id: shiftId })
        }
    }

    const onAddWeek = () => {
        const newItems = days.map((day) => {
            return {
                num: weeksNum + 1,
                day: day,
                timteable: null,
                schedule: null
            }
        })

        const newWeeks = [...weeks];
        newWeeks.push(...newItems);
        setWeeks(newWeeks);
        setWeeksNum(weeksNum + 1);
    }

    const onRemoveWeek = (weekNum) => {

        const newWeeks = [];
        let newMax = 0;
        
        for (let item of weeks) {
            if (item.num < weekNum) {
                newWeeks.push(item);
            } else if (item.num > weekNum) {
                item.num -= 1;
                newWeeks.push(item);
            }
            if (item.num > newMax) newMax = item.num;
        }

        setWeeks(newWeeks);
        setWeeksNum(weeksNum - 1);
        setMaxWeek(newMax)

    }

    return (
        <View
            maxWeek={maxWeek}
            weeksNum={weeksNum}
            weeks={weeks}
            shift={shift}
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            schedules={schedules}
            days={days}
            onAddWeek={onAddWeek}
            onRemoveWeek={onRemoveWeek}
            handleOnChange={handleOnChange}
            onReset={onReset}
        />
    )


}

export default FormSet;
