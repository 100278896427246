import React from 'react';
import FileField from 'helpers/FileField';
import { Formik, Form, Field } from 'formik';
import { ReactComponent as User } from "resources/svg/user.svg";
import { SERVER_URL } from '../../../settings';
import moment from 'moment';
import { Typeahead } from "react-bootstrap-typeahead";

function FormView({
  formikRef = () => { },
  edit = false,
  employee = {},
  geoZones = [],
  jobs = [],
  departments = [],
  regimes = [],
  contracts = [],
  payrolls = [],
  offices = [],
  shifts = [],
  reasonLeaves = [],
  bosses = [],
  areas = [],
  subareas = [],
  costCenters = [],
  groups = [],
  onSubmit,
  onCancel,
  fileInputRef
}) {
  return (
    <div className='container mt-3'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...employee,
          status: "ACTIVE",
          geolocation: employee.geolocation??false,
          has_photo: employee.has_photo??false,
          is_admin: employee?.perm_level == "ADMIN",
          geo_zones: employee?.employee_geo_zones?.map((employee_geo_zone) => employee_geo_zone.geo_zone),
        }}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, resetForm }) =>
          <Form>

            <FileField 
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }} 
              inputRef={fileInputRef} 
              setFieldValue={setFieldValue} 
              name="photo"
              multiple={false}
            />

            <div className='row d-flex align-items-center'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="key" class="input__field form-control" placeholder=" " value={values.key || ''} required />
                  <span class="input__label">
                    ID Empleado
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group d-flex justify-content-center' onClick={() => {
                  fileInputRef.current.click();
                }}>
                {
                  values.photo 
                    ? <>
                        <div className='d-flex justify-content-center align-items-center' style={{ width: "125px", height: "125px", borderRadius: "50%", overflow: "hidden" }}>
                          <img crossorigin="anonymous" src={(values.photo.path ? (SERVER_URL + "/" + values.photo.path) : values.photo.url)} alt="Foto" style={{ width: "125px", height: "125px", cursor: "pointer" }} />
                        </div>
                      </>
                    : <User style={{ width: "125px", height: "125px", cursor: "pointer" }} />
                }
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="pin" class="input__field form-control" placeholder=" " value={values.pin || ''} required />
                  <span class="input__label">
                    ID en lector
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="firstname" class="input__field form-control" placeholder=" " required value={values.firstname || ''} />
                  <span class="input__label">
                    Nombre(s)
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="dad_lastname" class="input__field form-control" placeholder=" " required value={values.dad_lastname || ''} />
                  <span class="input__label">
                    Apellido paterno
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="mom_lastname" class="input__field form-control" placeholder=" " value={values.mom_lastname || ''} />
                  <span class="input__label">Apellido materno</span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="job_id" class="input__field form-control" required value={values.job_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      jobs.map((job) =>
                        <option value={job.id}>{job.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Puesto
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="department_id" class="input__field form-control" required value={values.department_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      departments.map((department) =>
                        <option value={department.id}>{department.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Departamento
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="regime_id" class="input__field form-control" required value={values.regime_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      regimes.map((regime) =>
                        <option value={regime.id}>{regime.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Regimen
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="contract_id" class="input__field form-control" required value={values.contract_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      contracts.map((contract) =>
                        <option value={contract.id}>{contract.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Tipo de contrato
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="payroll_id" class="input__field form-control" required value={values.payroll_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      payrolls.map((payroll) =>
                        <option value={payroll.id}>{payroll.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Tipo de nómina
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="date" name="admission" class="input__field form-control" required placeholder=" " value={values.admission ? moment.utc(values.admission).format('YYYY-MM-DD') : ''} />
                  <span class="input__label">
                    Fecha de ingreso
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="office_id" class="input__field form-control" required value={values.office_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      offices.map((office) =>
                        <option value={office.id}>{office.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Sucursal
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="shift_id" class="input__field form-control" required={!values.has_planning} value={values.shift_id || ''} disabled={values.has_planning}>
                    <option value="">Seleccione una opción</option>
                    {
                      shifts.map((shift) =>
                        <option value={shift.id}>{shift.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Turno
                    {
                      !values.has_planning && <span className='text-danger fw-bold'>*</span>
                    }
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="date" name="start_shift" class="input__field form-control" placeholder=" " required value={values.start_shift ? moment.utc(values.start_shift).format('YYYY-MM-DD') : ''} />
                  <span class="input__label">
                    Fecha inicia turno
                    <span className='text-danger fw-bold'>*</span>
                  </span>
                </label>
              </div>
            </div>

            {/* <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="status" class="input__field form-control" value={values.status || ''}>
                    <option value="">Seleccione una opción</option>
                    <option value="ACTIVE">Activo</option>
                    <option value="LEAVE">Inactivo</option>
                  </Field>
                  <span class="input__label">Estatus</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field 
                    type="date" 
                    name="leave" 
                    class="input__field form-control" 
                    placeholder=" " 
                    value={values.leave ? moment.utc(values.leave).format('YYYY-MM-DD') : ''}
                    required={values.status === 'LEAVE'}
                  />
                  <span class="input__label">
                    Fecha de baja
                    {
                      values.status === 'LEAVE' && <span className='text-danger fw-bold'>*</span>
                    }
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field 
                    as="select" 
                    name="reason_leave_id" 
                    class="input__field form-control" 
                    value={values.reason_leave_id || ''} 
                    required={values.status === 'LEAVE'}
                  >
                    <option value="">Seleccione una opción</option>
                    {
                      reasonLeaves.map((reason_leave) =>
                        <option value={reason_leave.id}>{reason_leave.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">
                    Motivo de baja
                    {
                      values.status === 'LEAVE' && <span className='text-danger fw-bold'>*</span>
                    }
                  </span>
                </label>
              </div>
            </div> */}

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="rfc" class="input__field form-control" placeholder=" " value={values.rfc || ''} />
                  <span class="input__label">RFC</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="curp" class="input__field form-control" placeholder=" " value={values.curp || ''} />
                  <span class="input__label">CURP</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="nss" class="input__field form-control" placeholder=" " value={values.nss || ''} />
                  <span class="input__label">NSS</span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="email" class="input__field form-control" placeholder=" " value={values.email || ''} />
                  <span class="input__label">
                    Correo { values.geolocation && <span className='text-danger fw-bold'>*</span> }
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="salary" class="input__field form-control" placeholder=" " value={values.salary || ''} />
                  <span class="input__label">Salario diario</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="salary_monthly" class="input__field form-control" placeholder=" " value={values.salary_monthly || ''} />
                  <span class="input__label">Salario mensual</span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="boss_id" class="input__field form-control" value={values.boss_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      bosses.map((boss) =>
                        <option value={boss.id}>{boss.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Jefe directo</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="area_id" class="input__field form-control" value={values.area_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      areas.map((area) =>
                        <option value={area.id}>{area.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Área</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="subarea_id" class="input__field form-control" value={values.subarea_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      subareas.map((subarea) =>
                        <option value={subarea.id}>{subarea.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Sub área</span>
                </label>
              </div>
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="cost_center_id" class="input__field form-control" value={values.cost_center_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      costCenters.map((cost_center) =>
                        <option value={cost_center.id}>{cost_center.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Centro de costos</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="text" name="company_payroll" class="input__field form-control" placeholder=" " value={values.company_payroll || ''} />
                  <span class="input__label">Empresa nómina</span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <div className='row my-0'>
                  <div className='col d-flex align-items-center'>
                    <input 
                      type="checkbox" 
                      name="is_admin" 
                      className="mx-2" 
                      checked={values.is_admin??false} 
                      onChange={(e) => {
                        setFieldValue('is_admin', e.target.checked);
                      }} 
                    />
                    <span> Administrador en lector </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col d-flex align-items-center'>
                    <input 
                      type="checkbox" 
                      name="has_planning" 
                      className="mx-2" 
                      checked={values.has_planning??false} 
                      onChange={(e) => {
                        setFieldValue('has_planning', e.target.checked);
                        setFieldValue('shift_id', null);
                      }} 
                    />
                    <span> Planeación semanal </span>
                  </div>
                </div>
              </div>
              {/* <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="group_id" class="input__field form-control" value={values.group_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      groups.map((group) =>
                        <option value={group.id}>{group.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Grupo de trabajo</span>
                </label>
              </div> */}
            </div>

            <div className='row my-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field type="password" name="geolocation_password" class="input__field form-control" placeholder=" " required={values.geolocation && !employee.geolocation} disabled={!values.geolocation} />
                  <span class="input__label">
                    Contraseña geo.
                    {values.geolocation && !employee.geolocation && <span className='text-danger fw-bold'>*</span> }
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Field as="select" name="time_zone" class="input__field form-control" value={values.time_zone || ''} required={values.geolocation} disabled={!values.geolocation}>
                    <option value="">Seleccione una opción</option>
                    <option value="-12:00">GMT-12:00</option>
                    <option value="-11:00">GMT-11:00</option>
                    <option value="-10:00">GMT-10:00</option>
                    <option value="-09:00">GMT-9:00</option>
                    <option value="-08:00">GMT-8:00</option>
                    <option value="-07:00">GMT-7:00</option>
                    <option value="-06:00">GMT-6:00</option>
                    <option value="-05:00">GMT-5:00</option>
                    <option value="-04:00">GMT-4:00</option>
                    <option value="-03:00">GMT-3:00</option>
                    <option value="-02:00">GMT-2:00</option>
                    <option value="-01:00">GMT-1:00</option>
                    <option value="+00000">GMT</option>
                    <option value="+01:00">GMT+1:00</option>
                    <option value="+02:00">GMT+2:00</option>
                    <option value="+03:00">GMT+3:00</option>
                    <option value="+04:00">GMT+4:00</option>
                    <option value="+05:00">GMT+5:00</option>
                    <option value="+06:00">GMT+6:00</option>
                    <option value="+07:00">GMT+7:00</option>
                    <option value="+08:00">GMT+8:00</option>
                    <option value="+09:00">GMT+9:00</option>
                    <option value="+10:00">GMT+10:00</option>
                    <option value="+11:00">GMT+11:00</option>
                    <option value="+12:00">GMT+12:00</option>
                    <option value="+13:00">GMT+13:00</option>
                  </Field>
                  <span class="input__label">
                    Zona horaria
                    {values.geolocation && <span className='text-danger fw-bold'>*</span> }
                  </span>
                </label>
              </div>
              <div className='col-md-4 form-group'>
                <div className='row my-0'>
                  <div className='col d-flex align-items-center'>
                    <input type="checkbox" name="geolocation" className="mx-2" checked={values.geolocation??false} onChange={(e) => setFieldValue('geolocation', e.target.checked)} />
                    <span> Geolocalización </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col d-flex align-items-center'>
                    <input type="checkbox" name="has_photo" className="mx-2" checked={values.has_photo??false} onChange={(e) => setFieldValue('has_photo', e.target.checked)} />
                    <span> Fotografía </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-md-4 form-group'>
                <label class="input">
                  <Typeahead
                    key={Math.random()}
                    className="form-control input__field"
                    options={geoZones}
                    disabled={!values.geolocation}
                    placeholder="Buscar geocercas"
                    multiple={true}
                    selected={values.geo_zones}
                    style={{
                      display: values.geolocation ? 'block' : 'none',
                    }}
                    renderToken={(option, props, idx) => {
                      return (
                        <span key={idx} className="badge bg-primary me-1">
                          {option.key} - {option.description} 
                            <span 
                              className='mx-1' 
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const newSelected = values.employee.geo_zones.filter((geo_zone) => geo_zone.id !== option.id)
                                setFieldValue("geo_zones", newSelected)
                                props.onRemove(option)
                              }}
                            >
                              &times;
                            </span>
                        </span>
                      )
                    }}
                    onChange={(selected) => setFieldValue("geo_zones", selected)}
                    labelKey={(option) => `${option.key} - ${option.description}`}
                  />
                  <span class="input__label">
                    {
                      values.geolocation && <>Geocercas </>
                    }
                  </span>
                </label>
              </div>
            </div>

            <div>
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                {edit ? "Editar" : "Guardar"}
              </button>

              <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                Cancelar
              </button>
            </div>

          </Form>
        }
      </Formik>
    </div>
  )
}

export default FormView;