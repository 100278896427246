import moment from "moment/moment";
import React from "react"
import { formatISODate, formatHolidaysHours, formatHolidaysDays } from "utils/date";
import Modal from "helpers/Modal";
import { getNameIncidence } from "utils/constants";

function HolidayLogsListView({ 
	logs = [], 
	theme,
	showIncidenceModal,
	holidayLog,
	setShowIncidenceModal,
	setHolidayLog,
}) {
	return (
		<>

			{
				logs.map(log => 
					log.logs && log.logs.length > 0
						?	<div className="row">
								<div className="col-md-12">

									<table className="table-responsive table table-sm b-0" style={{border: "0"}}>
										<tr>
											<td className="p-2">
												<span className="fw-bold me-2">Nombre:</span>
												{log.firstname} {log.dad_lastname} {log.mom_lastname}
											</td>
											<td className="p-2">
												<span className="fw-bold me-2">ID de empleado:</span>
												{log.key}
											</td>
										</tr>
										<tr>
											<td className="p-2">
												<span className="fw-bold me-2">Departamento:</span>
												{log.department ? log.department.description : ""}
											</td>
											<td className="p-2">
												<span className="fw-bold me-2">Puesto:</span>
												{log.job ? log.job.description : ""}
											</td>
										</tr>
										<tr>
											<td className="p-2">
												<span className="fw-bold me-2">Sucursal:</span>
												{log.office ? log.office.description : ""}
											</td>
											<td className="p-2">
												<span className="fw-bold me-2">Tipo de nómina:</span>
												{log.payroll ? log.payroll.description : ""}
											</td>
										</tr>
										<tr>
											<td className="p-2">
												<span className="fw-bold me-2">Fecha de ingreso:</span>
												{moment(log.admission).format("DD-MM-YYYY")}
											</td>
											<td className="p-2">
												<span className="fw-bold me-2">Antigüedad:</span>
												{moment().diff(moment(log.admission), 'years')} años
											</td>
										</tr>
									</table>

									<div className="mx-3">
										<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
											<thead>
												<tr role="row">
													<th>Fecha</th>
													<th>Movimiento</th>
													<th>Días disponibles</th>
													<th>Días solicitados</th>
													<th>Saldo</th>
												</tr>
											</thead>
											<tbody>
												{
													log.logs.map((holidayLog) =>
														<tr>
															<td>
																{holidayLog.type !== "NOW" ? <>{formatISODate(holidayLog.date)}</> : ""}
															</td>
															<td>
																{function(){
																	if(holidayLog.type === "INITIAL") 
																		return "Saldo inicial";
																	if(holidayLog.type === "MOVEMENT") 
																		return `Movimiento de saldo por antigüedad (${moment(holidayLog.date).diff(moment(log.admission), 'years')} años) - 
																			${sessionStorage.getItem("hour_holidays") == "true" ? (holidayLog.hours + " horas") : (holidayLog.days  + " días")}`;
																	if(holidayLog.type === "REQUEST")
																		return <div 
																				onClick={() => {  
																					setShowIncidenceModal(true);
																					setHolidayLog({
																						log: holidayLog,
																						employee: log
																					});
																				}}
																				style={{
																					cursor: "pointer",
																					textDecoration: "underline",
																					color: "#007bff"
																				}}
																			>
																			{`Solicitud folio ${holidayLog.incidence_request.folio}, ${formatISODate(holidayLog.incidence_request.start_date)} - ${formatISODate(holidayLog.incidence_request.end_date)}`}
																		</div>
																	if(holidayLog.type === "NOW")
																		return "Saldo actual";
																	if(holidayLog.type === "UPDATE")
																		return "Actualización de saldo";
																}()}
															</td>
															<td>
																{ 
																	holidayLog.type === "INITIAL" || holidayLog.type === "MOVEMENT" || holidayLog.type === "UPDATE"
																		? 	(
																				sessionStorage.getItem("hour_holidays") == "true" 
																					? formatHolidaysHours(holidayLog.available.hours) 
																					: formatHolidaysDays(holidayLog.available.days)
																			) 
																		: ""
																}
															</td>
															<td>
																{
																	holidayLog.type === "REQUEST" 
																		? 	(
																				sessionStorage.getItem("hour_holidays") == "true" 
																					? formatHolidaysHours(holidayLog.requested.hours) 
																					: formatHolidaysDays(holidayLog.requested.days) 
																			)
																		: ""
																}
															</td>
															<td>
																{
																	holidayLog.type === "MOVEMENT" || holidayLog.type === "REQUEST" || holidayLog.type === "NOW"
																		? 	(
																				sessionStorage.getItem("hour_holidays") == "true" 
																					? formatHolidaysHours(holidayLog.balance.hours) 
																					: formatHolidaysDays(holidayLog.balance.days)
																			)
																		: ""
																}
															</td>
														</tr>
													)
												}
											</tbody>
										</table>
									</div>
									
									<hr/>

								</div>
							</div>
						: 	null
				)
			}

			<Modal
				title={"Solicitud de incidencias"}
				isVisible={showIncidenceModal}
				setIsVisible={() => setShowIncidenceModal(false)}
			>	
				<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<tbody>
						<tr>
							<td className="fw-bold">
								Folio
							</td>
							<td>
								SI - {holidayLog && holidayLog?.log?.incidence_request?.folio}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Empleado
							</td>
							<td>
								{holidayLog && holidayLog?.employee?.firstname} {holidayLog && holidayLog?.employee?.dad_lastname} {holidayLog && holidayLog?.employee?.mom_lastname}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Incidencia
							</td>
							<td>
								{holidayLog && getNameIncidence(holidayLog?.log?.incidence_request?.incidence?.type)}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Fecha de inicio
							</td>
							<td>
								{holidayLog && formatISODate(holidayLog?.log?.incidence_request?.start_date)}
								{holidayLog && holidayLog?.log?.incidence_request?.start_hour && ` ${holidayLog?.log?.incidence_request?.start_hour}`}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Fecha de fin
							</td>
							<td>
								{holidayLog && formatISODate(holidayLog?.log?.incidence_request?.end_date)}
								{holidayLog && holidayLog?.log?.incidence_request?.end_hour && ` ${holidayLog?.log?.incidence_request?.end_hour}`}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Solicitante
							</td>
							<td>
								{holidayLog && holidayLog?.log?.incidence_request?.applicant?.firstname} {holidayLog && holidayLog?.log?.incidence_request?.applicant?.lastname}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Aut. RH
							</td>
							<td>
								{holidayLog && holidayLog?.log?.incidence_request?.authorizer_rh?.firstname} {holidayLog && holidayLog?.log?.incidence_request?.authorizer_rh?.lastname}
								{holidayLog && holidayLog?.log?.incidence_request?.date_auth_rh && <div>{moment(holidayLog?.log?.incidence_request?.date_auth_rh)?.format("DD-MM-YYYY HH:mm")}</div>}
							</td>
						</tr>
						<tr>
							<td className="fw-bold">
								Aut. Jefe
							</td>
							<td>
								{holidayLog && holidayLog?.log?.incidence_request?.authorizer_boss?.firstname} {holidayLog && holidayLog?.log?.incidence_request?.authorizer_boss?.lastname}
								{holidayLog && holidayLog?.log?.incidence_request?.date_auth_boss && <div>{moment(holidayLog?.log?.incidence_request?.date_auth_boss)?.format("DD-MM-YYYY HH:mm")}</div>}
							</td>
						</tr>
					</tbody>
				</table>
				<div class="mt-3">
					<button type="button" class="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => setShowIncidenceModal(false)}>
						<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
						Cerrar
					</button>
				</div>
			</Modal>

		</>
	);
}

export default HolidayLogsListView;