import React, { useState, useEffect, useRef } from "react"
import { usePost, useGetCall } from "utils/api"
import { toast } from "react-toastify"
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import { showResponseMessage } from "utils/message"

function FormLeave({ id, company, setIsVisible, refresh }) {

  const formikRef = useRef()
  const [reasonLeaves, setReasonLeaves] = useState([])
  const [refreshValue, setRefreshValue] = useState(null)

  if (refresh !== refreshValue)
    setRefreshValue(refresh)

  const [callReasonsLeave, reqReasonsLeave] = useGetCall('/reasons_leave', {
      onCompleted: (response) => {
          setReasonLeaves(response.data);
      }, onError: (error) => {
          showResponseMessage(error.status, "reasons_leave", "motivos de baja", "read")
      }
  })

  const [callSet, reqSet] = usePost("/employees/update_leave", {
    onCompleted: () => {
      console.log("Empleado dado de baja correctamente")
        if (!toast.isActive("toast-leave-employee"))
            toast.success("Empleado dado de baja correctamente", { toastId: "toast-leave-employee" })
        setIsVisible(false)
        formikRef.current.setValues({})
    },
    onError: (err) => {
        console.error(err)
        if (!toast.isActive("toast-leave-employee"))
            toast.error("Error al dar de baja al empleado", { toastId: "toast-leave-employee" })
    }
  })

  const onSubmit = (values, { resetForm }) =>
    callSet({ employee_id: id, ...values });

  useEffect(() => {
    callReasonsLeave({ company });
  }, [company])

  return <>
    <Formik
      innerRef={formikRef}
      initialValues={{}}
      onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm }) =>
        <Form>

          <div class="mb-3">
              <div class="form-group">
                  <label class="input">
                    <Field type="date" name="leave" class="input__field form-control" required placeholder=" " value={values.leave ? moment.utc(values.leave).format('YYYY-MM-DD') : ''} />
                    <span class="input__label">
                      Fecha de baja
                      <span className='text-danger fw-bold'>*</span>
                    </span>
                  </label>
              </div>
          </div>

          <div className='mb-3'>
            <label class="input">
              <Field 
                as="select" 
                name="reason_leave_id" 
                class="input__field form-control" 
                value={values.reason_leave_id || ''} 
                required
              >
                <option value="">Seleccione una opción</option>
                {
                  reasonLeaves.map((reason_leave) =>
                    <option value={reason_leave.id}>{reason_leave.description}</option>
                  )
                }
              </Field>
              <span class="input__label">
                Motivo de baja
                <span className='text-danger fw-bold'>*</span>
              </span>
            </label>
          </div>

          <div class="mb-3">
              <div class="form-group">
                  <label class="input">
                      <Field as="textarea" name="leave_comments" class="form-control input__field" placeholder=" " value={values.leave_comments || ''} rows={4} />
                      <span class="input__label">
                          Comentarios de baja
                      </span>
                  </label>
              </div>
          </div>

          <div class="pt-3">
            <button type="submit" class="btn btn-primary">
              <i class="bi bi-check" style={{ marginRight: "10px" }}></i>
              Dar de baja
            </button>

            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={() => setIsVisible(false)}>
              <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
              Cancelar
            </button>
          </div>

        </Form>
      }
    </Formik>
  </>

}

export default FormLeave