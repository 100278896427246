import React, { useState } from "react";

import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/extraTimeRequests/ExtraTimeRequests.view";

function ExtraTimeRequests({ company = {},}) {
  return (
    <View
      company={company}
    />
  );
}

export default ExtraTimeRequests;