import React from "react";
import ExtraTimeRequestsList from "components/crh/extraTimeRequests/List";

function IncidencesAuthView({
	company = {},
}) {

	return (
		<>
			<div className="content container-fluid p-5">
				<ExtraTimeRequestsList
					refresh={Math.random()}
					company={company}
				/>
			</div>
		</>
	);
}

export default IncidencesAuthView;