import React from "react";
import Filters from "components/crh/reports/retardment/Filters";
import Modal from "helpers/Modal";
import moment from "moment";
import { getWeekDayName, getAbbreviationIncidence } from "utils/constants";
import { getTotalTime } from "utils/reports";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';

function RetardmentsReportView({
	user = {},
	permissionExcel,
	permissionPdf,
	permissionRead,
	setLoading,
	onDownloadXlsx,
	onDownloadXlsxColumns,
	onDownloadCSV,
	reportRef,
	reports,
	filterModal,
	filters,
	setFilters,
	showFilterModal,
	hideFilterModal,
	onChangeSearch,
	handleKeyUp,
}) {

	return (
		<>

			<div className="content container-fluid p-5">
				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de retardos</h1>
						</div>

						<div className="col-sm-auto">
							{
								permissionPdf && (
									<ReactToPrint
										debug={true}
										content={() => reportRef.current}
										trigger={() => (
											<button className="btn bg-danger text-white mx-1">
												<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
												PDF
											</button>
										)}
									/>
								)
							}
							{
								permissionExcel && (
									<>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Excel
										</button>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsxColumns}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Excel Columnas
										</button>
										<button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
											<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
											CSV
										</button>
									</>
								)
							}
							{
								permissionRead && (
									<button type="button" className="btn btn-primary" onClick={showFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-header card-header-content-md-between">
						<div class="d-flex justify-content-between mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar empleado"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
							<div class="d-flex justify-content-right mx-3">
								<span class="pt-2 mx-1">Total empleados: </span>
								<span class="pt-2 border-0">{reports.length}</span>
							</div>
						</div>
					</div>
				</div>

				<div ref={reportRef}>

					<div className="report-header">

						<div className="d-flex justify-content-center">
							<div className="logo mt-6">
								{
									user?.company?.logo?.url ? (
										<img crossorigin="anonymous" src={user.company.logo.url} height="300" alt="Logo empresa" style={{ borderRadius: "50%" }} />
									) : (
										<img src={NoImage} alt="" style={{ borderRadius: "50%" }} height="300" />
									)
								}
							</div>
						</div>

						<div className="text-center">
							<h1 className="mb-3">{user?.company?.name}</h1>
						</div>

						<div className="text-center">
							<h2 className="mb-3">Reporte de retardos</h2>
						</div>

						<div className="text-center">
							<h4 className="">
								{
									!filters.start_date && !filters.end_date
										? "Todos los registros"
										: moment.utc(filters.start_date).format("DD [de] MMMM [de] YYYY") + " - " + moment.utc(filters.end_date).format("DD [de] MMMM [de] YYYY")
								}
							</h4>
						</div>

					</div>

					<div className="pagebreak"></div>

					<div class="row">
						{
							reports.map((employeeReport) => (
								<div className="col-md-12 mb-5">

									<table class="table bg-dark p-3" style={{ color: "white", margin: "0", zIndex: 2 }}>
										<tr>
											<td class="p-3">ID</td>
											<td>{employeeReport.employee.key}</td>
											<td>Nombre</td>
											<td>{employeeReport.employee.firstname + " " + employeeReport.employee.dad_lastname + " " + employeeReport.employee.mom_lastname}</td>
											<td>Puesto</td>
											<td>{employeeReport.employee.job ? employeeReport.employee.job.description : ""}</td>
											<td>Departamento</td>
											<td>{employeeReport.employee.department ? employeeReport.employee.department.description : ""}</td>
										</tr>
									</table>

									<div class="table-responsive datatable-custom report-content" style={{ marginTop: "-5px" }}>
										<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
											<thead style={{ position: "sticky", top: "0", zIndex: 1 }}>
												<tr role="row">
													<th>Fecha</th>
													<th>Día</th>
													<th>Horario</th>
													<th>Entrada</th>
													<th>Salida Descanso</th>
													<th>Entrada Descanso</th>
													<th>Salida</th>
													<th>Retardo</th>
													<th>Resumen de Incidencias</th>
												</tr>
											</thead>
											<tbody>
												{
													employeeReport.reports.map((record) => (
														<tr key={record.id} role="row">
															<td>{moment(record.date).format("DD/MM/YYYY")}</td>
															<td>{getWeekDayName(record.week_day)}</td>
															<td>{JSON.parse(record.schedule)?.entry_start.substring(0, 5)} - {JSON.parse(record.schedule)?.exit_start.substring(0, 5)}</td>
															<td>{record.entry ? record.entry.substring(0, 5) : "-"}</td>
															<td>{record.exit_break ? record.exit_break.substring(0, 5) : "-"}</td>
															<td>{record.return_break ? record.return_break.substring(0, 5) : "-"}</td>
															<td>{record.exit ? record.exit.substring(0, 5) : "-"}</td>
															<td>{record.retardment ? record.retardment.substring(0, 5) : "-"}</td>
															<td>
																{
																	JSON.parse(record.incidences).map((incidence, idx) => (
																		<>
																			{getAbbreviationIncidence(incidence.incidence)} / {" "}
																		</>

																	))
																}
															</td>
														</tr>
													))
												}
												<tr style={{ borderTop: "solid 3px black" }}>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td><b>Total</b></td>
													<td><b>{getTotalTime(employeeReport.reports, "retardment")}</b></td>
													<td><b>Total de retardos: {employeeReport.reports.length}</b></td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
							))
						}
					</div>
				</div>

			</div>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideFilterModal}
			>
				<Filters
					setLoading={setLoading}
					hideModal={hideFilterModal}
					filters={filters}
					setFilters={setFilters}
				/>
			</Modal>

		</>
	)

}

export default RetardmentsReportView;