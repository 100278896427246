import React from 'react';
import { Formik, Form, Field } from 'formik';
import "resources/styles/Incidences.scss";

function FormView({
    payrolls,
    payrollPeriods,
    offices,
    jobs,
    departments,
    onSubmit,
    onCancel,
}) {

    return (
        <div className='container mt-3'>
            <Formik
                initialValues={{}}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        {/* Payrolls type */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Tipo de nómina</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="payroll_id" className="form-control" value={values.payroll_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {
                                        payrolls.map(payroll =>
                                            <option key={payroll.id} value={payroll.id}>{payroll.description}</option>
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        {/* Payrolls type */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Periodo de nómina</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="payroll_period_id" className="form-control" value={values.payroll_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {	
                                        payrollPeriods.map((payrollPeriod) => (
                                            <option key={payrollPeriod.id} value={payrollPeriod.id}>
                                                {payrollPeriod.description}
                                            </option>
                                        ))
                                    }
                                </Field>
                            </div>
                        </div>

                        {/* Date range */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Fecha de inicio</h4>
                            </div>
                            <div className="col-md-8">
                                <Field type="date" name="start_date" class="form-control input__field" />
                            </div>
                        </div>

                        {/* Date range */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Fecha de fin</h4>
                            </div>
                            <div className="col-md-8">
                                <Field type="date" name="end_date" class="form-control input__field" />
                            </div>
                        </div>

                        {/* Offices */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Sucursal</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="office_id" className="form-control" value={values.office_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {
                                        offices.map(office =>
                                            <option key={office.id} value={office.id}>{office.description}</option>
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        {/* Jobs */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Puesto</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="job_id" className="form-control" value={values.job_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {
                                        jobs.map(job =>
                                            <option key={job.id} value={job.id}>{job.description}</option>
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        {/* Departments */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Departamento</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="department_id" className="form-control" value={values.department_id || ''}>
                                    <option value="">Seleccione una opción</option>
                                    {
                                        departments.map(department =>
                                            <option key={department.id} value={department.id}>{department.description}</option>
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        {/* Status */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>T.E. a mostrar</h4>
                            </div>
                            <div className="col-md-8">
                                <Field as="select" name="status" className="form-control">
                                    <option value="">Todos</option>
                                    <option value="ACTIVE">Sin autorizar</option>
                                    <option value="CANCELED">Cancelado</option>
                                    <option value="AUTH_RH">Autorizada por RH</option>
                                </Field>
                            </div>
                        </div>

                        {/* Only round */}
                        <div className="row my-2">
                            <div className="col-md-4">
                                <h4>Solo T.E. redondeado</h4>
                            </div>
                            <div className="col-md-8">
                                <div className="form-check form-switch" id="status">                                    
                                    <Field
                                        class="form-check-input" 
                                        style={{ height: "22px", width: "40px" }}
                                        type="checkbox" 
                                        id="status-switch"
                                        checked={values.only_round}
                                        onChange={(e) => {
                                            setFieldValue("only_round", e.target.checked)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                Aplicar filtros
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;