import React from "react";
import LoadCsvField from "helpers/LoadCsvFIeld";

function BulkLoadView({
    onDownloadExampleFile,
    inputFileRef,
    onLoadFile,
    data,
    validations,
    onSubmit,
    onCancel,
    onReset
}) {

    return (
        <div className='container mt-3'>

            <div className="row">
                <div className="col-md-6">
                    <div class="mb-3 d-flex">
                        <button
                            className="btn btn-primary"
                            onClick={onDownloadExampleFile}
                            style={{ marginRight: "10px" }}
                        >
                            Descargar plantilla
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={onReset}
                        >
                            Reestablecer datos
                        </button>
                    </div>
                </div>
                <div className="col-md-6">
                    <div class="mb-3">
                        <div class="form-group">
                            <label class="input">
                                <LoadCsvField
                                    inputRef={inputFileRef}
                                    type="file"
                                    name="file"
                                    className="form-control input__field"
                                    onLoad={onLoadFile}
                                    required
                                />
                                <span class="input__label">
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive datatable-custom position-relative">
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "30vh" }}>
                    <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                        {
                            data.headers && data.rows
                                ? <>
                                    <thead>
                                        <tr role="row">
                                            {
                                                data.headers.map(header =>
                                                    <th>{header}</th>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.rows.map((cols, indexRow) => {
                                                const error = validations.find((validation) => validation.index === indexRow);
                                                if (error) {
                                                    const type = error.status;
                                                    if (type === "INVALID") {
                                                        return (
                                                            <tr role="row" class="bg-secondary">
                                                                {cols.map(col => (<td>{col}</td>))}
                                                            </tr>
                                                        )
                                                    }
                                                    if (type === "DUPLICATED") {
                                                        return (
                                                            <tr role="row" class="bg-warning">
                                                                {cols.map(col => (<td>{col}</td>))}
                                                            </tr>
                                                        )
                                                    }
                                                    if (type === "EMPTY") {
                                                        const errorCols = error.cols;
                                                        return (
                                                            <tr role="row">
                                                                {cols.map((col, idx) => {
                                                                    if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                        return (<td class="bg-danger">{col}</td>)
                                                                    }
                                                                    return (<td>{col}</td>)
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    if (error.status === "NOT_FOUND") {
                                                        const errorCols = error.cols;
                                                        return (
                                                            <tr role="row">
                                                                {cols.map((col, idx) => {
                                                                    if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                        return (<td class="bg-primary" style={{ color: "white" }}>{col}</td>)
                                                                    }
                                                                    return (<td>{col}</td>)
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                }
                                                return (
                                                    <tr role="row" class="bg-success" style={{ color: "white" }}>
                                                        {cols.map(col => (<td>{col}</td>))}
                                                    </tr>
                                                )

                                            })
                                        }
                                    </tbody>
                                </>
                                : <thead>
                                    <tr>
                                        <td>
                                            La previsualización no está disponible
                                        </td>
                                    </tr>
                                </thead>
                        }
                    </table>
                </div>
            </div>

            <div class="d-flex pt-3 justify-content-between">
                <div>
                    <button type="submit" class="btn btn-primary" onClick={onSubmit}>
                        <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                        Insertar datos
                    </button>
                    <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                        <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                        Cancelar
                    </button>
                </div>
                <table class="w-50">
                    <tr>
                        <td><div class="bg-secondary" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila de datos inválidos</td>
                        <td><div class="bg-warning" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila duplicada en el sistema</td>
                    </tr>
                    <tr>
                        <td><div class="bg-danger" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Datos obligatorios faltantes</td>
                        <td><div class="bg-success" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila correcta y lista para insertarse</td>
                    </tr>
                </table>
            </div>

        </div>
    );
}

export default BulkLoadView;