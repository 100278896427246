import React from "react";
import { getWeekDayName } from "utils/constants";
import { TimePicker } from 'antd';
import Modal from "helpers/Modal";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';
import moment from "moment";
import "moment/locale/es-mx";
moment.locale('es');

const getAvailableRequests = (extraTimeRequests, user) => {

	const availableRequests = [];
	for (const extraTimeRequest of extraTimeRequests) {

		const status = extraTimeRequest.status;
		if (status === "CANCELED") continue;
		if (status === "AUTH_RH" && !extraTimeRequest.edit) continue;

		const userProfile = user.profile;
		if (userProfile === "" || userProfile == null) continue;
		if (status === "ACTIVE" || extraTimeRequest.edit) availableRequests.push(extraTimeRequest);

	}

	return availableRequests;

}

const getButtons = (extraTimeRequest, user, onAuth, onEdit, onCancel) => {

	if (extraTimeRequest.status === "ACTIVE" || extraTimeRequest.edit) {
		return (
			<>
				<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onAuth(extraTimeRequest)}>
					<i class="bi bi-fingerprint"></i>
				</button>
				<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(extraTimeRequest.id)}>
					<i class="bi bi-pencil"></i>
				</button>
				<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(extraTimeRequest.id)}>
					<i class="bi bi-x-circle"></i>
				</button>
			</>
		)

	}
	else if (extraTimeRequest.status === "AUTH_RH") {
		return (
			<>
				{/* <button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(extraTimeRequest.id)}>
					<i class="bi bi-pencil"></i>
				</button> */}
				<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onCancel(extraTimeRequest.id)}>
					<i class="bi bi-x-circle"></i>
				</button>
			</>
		)
	}

}

function ListView({ 
	reportRef,
	permissionPdf,
	filters,
	addModal, 
	selected, 
	setSelected, 
	user, 
	onAuth, 
	onAuthGroup, 
	onCancel,
	onEdit, 
	extraTimeRequests = [], 
	onSelectCheckbox,
	editExtraTimeRequest, 
	setExtraTimeRequests,
	onChangeSearch,
	handleKeyUp,
	modalTitle,
	isVisible,
	setIsVisible,
	modalContent
}) {

	return (
		<>
			<div className="page-header mb-3">
				<div className="row align-items-end">

					<div className="col-sm mb-2 mb-sm-0">
						<h1 className="page-header-title">Autorización de tiempo extra</h1>
					</div>

					<div className="col-sm-auto">
						{
							permissionPdf && (
								<ReactToPrint
									debug={true}
									content={() => reportRef.current}
									trigger={() => (
										<button className="btn bg-danger text-white mx-1">
											<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
											PDF
										</button>
									)}
								/>
							)
						}
						<button type="button" className="btn btn-primary mx-1"
							onClick={addModal}
						>
							<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
							Filtros de búsqueda
						</button>
						{
							(user.profile === "RH") ? (
								<button type="button" className="btn btn-success" onClick={onAuthGroup}>
									<i className="bi bi-fingerprint" style={{ marginRight: "7px" }}></i>
									Aut. RH
								</button>
							) : null
						}
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-header card-header-content-md-between">
					<div class="d-flex justify-content-between mb-2 mb-md-0">
						<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
							<div class="input-group-prepend input-group-text" >
								<i class="bi-search"></i>
							</div>
							<input
								type="search"
								class="form-control"
								placeholder="Buscar empleado"
								onChange={onChangeSearch}
								onKeyUp={handleKeyUp}
							/>
						</div>
						<div class="d-flex justify-content-right mx-3">
							<span class="pt-2 mx-1">Total empleados: </span>
							<span class="pt-2 border-0">{extraTimeRequests.length}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="" ref={reportRef}>

				<div className="report-header">

					<div className="d-flex justify-content-center">
						<div className="logo mt-6">
							{
								user?.company?.logo?.url ? (
									<img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
								) : (
									<img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
								)
							}
						</div>
					</div>

					<div className="text-center">
						<h1 className="mb-3">{user?.company?.name}</h1>
					</div>

					<div className="text-center">
						<h2 className="mb-3">Autorización de tiempo extra</h2>
					</div>

					<div className="text-center">
						<h4 className="">
							{
								!filters.start_date && !filters.end_date
									? "Todos los registros"
									: filters.start_date + " - " + filters.end_date
							}
						</h4>
					</div>

				</div>

				<div className="pagebreak"></div>

				<div className="report-content">
					{
						extraTimeRequests.map((request) => 
							<>
								<table class="table dataTable no-footer bg-dark p-3 mb-0" style={{ color: "white", margin: "0", zIndex: 2 }}>
									<tr>
										<td class="p-3">ID</td>
										<td>{request.employee.key}</td>
										<td>Nombre</td>
										<td>{request.employee.firstname + " " + request.employee.dad_lastname + " " + request.employee.mom_lastname}</td>
										<td>Puesto</td>
										<td>{request.employee.job ? request.employee.job.description : ""}</td>
										<td>Departamento</td>
										<td>{request.employee.department ? request.employee.department.description : ""}</td>
									</tr>
								</table>
								<div class="table-responsive datatable-custom position-relative mb-3 mt-0 pt-0">
									<div class="dataTables_wrapper no-footer pl-3 pr-3 mt-0 pt-0" style={{ maxHeight: "60vh" }}>
										<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
											<thead>
												<tr role="row">
													<th>Fecha</th>
													<th>Día</th>
													<th>Horario</th>
													<th>Entrada</th>
													<th>Salida</th>
													<th>Retardo</th>
													<th>T.E.</th>
													<th>T.E. Red.</th>
													<th>Por autorizar</th>
													<th>Autorizado</th>
													<th>Usuario Aut.</th>
													<th style={{ width: "90px" }}>
														<div class="text-center align-items-center">
															<input type="checkbox" class="form-check-input"
																style={{ width: "25px", height: "25px" }}
																onClick={() => {
																	const items = getAvailableRequests(request.extraTimeRequests, user);
																	const selectedItems = selected[request.employee.id] || [];
																	if (selectedItems.length === items.length) {
																		setSelected({
																			...selected,
																			[request.employee.id]: []
																		});
																	} 
																	else {
																		setSelected({
																			...selected,
																			[request.employee.id]: items.map((item) => item.id)
																		});
																	}
																}}
																checked={function(){
																	const items = getAvailableRequests(request.extraTimeRequests, user);
																	return selected[request.employee.id]?.length === items.length && items.length > 0;
																}()}
															/>
														</div>
													</th>
													<th 
														className="report-hidden"
														style={{ width: "170px" }}
													>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody>
												{
													request.extraTimeRequests.map((extraTimeRequest) => (
														<tr key={extraTimeRequest.id} role="row">
															<td>
																{moment(extraTimeRequest.incidences_report.date).format("DD/MM/YYYY")}
															</td>
															<td style={{ whiteSpace: "nowrap" }}>{getWeekDayName(extraTimeRequest.incidences_report.week_day)}</td>
															<td style={{ whiteSpace: "nowrap" }}>
																{
																	function () {
																		try {
																			const schedule = JSON.parse(extraTimeRequest.incidences_report.schedule);
																			if (schedule) {
																				if (schedule.has_break) {
																					return "Descanso";
																				} else {
																					return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
																				}
																			} else {
																				return "";
																			}
																		} catch (e) {
																			return "";
																		}
																	}()
																}
															</td>
															<td>{extraTimeRequest.incidences_report.entry?.substring(0, 5)}</td>
															<td>{extraTimeRequest.incidences_report.exit?.substring(0, 5)}</td>
															<td>{extraTimeRequest.incidences_report.retardment?.substring(0, 5)}</td>
															<td>{extraTimeRequest.incidences_report.extra_time?.substring(0, 5)}</td>
															<td>{extraTimeRequest.incidences_report.extra_time_round?.substring(0, 5)}</td>
															<td>
																{
																	(extraTimeRequest.status !== "AUTH_RH" || extraTimeRequest.edit) &&
																		<>
																			{
																				editExtraTimeRequest.includes(extraTimeRequest.id)
																					? <TimePicker
																							defaultValue={moment(extraTimeRequest.extra_time_auth, "HH:mm:ss")}
																							format="HH:mm"
																							onChange={(time, timeString) => {

																								const newExtraTimeRequests = extraTimeRequests.map((request) => {
																									
																									if (request.employee.id === extraTimeRequest.employee_id) 
																										request.extraTimeRequests = request.extraTimeRequests.map((item) => {
																											if (item.id === extraTimeRequest.id) item.extra_time_auth = timeString;
																											return item;
																										})

																									return request;

																								});

																								setExtraTimeRequests(newExtraTimeRequests);

																							}}
																						/>
																					: extraTimeRequest.extra_time_auth?.substring(0, 5)
																			}
																		</>
																}
															</td>
															<td>{extraTimeRequest.status === "AUTH_RH" && extraTimeRequest.extra_time_auth}</td>
															<td>
																{
																	extraTimeRequest.status === "AUTH_RH" && 
																	<>
																		<p>{moment(extraTimeRequest?.date_auth).format("DD/MM/YYYY HH:mm:ss")}</p>
																		<p>{extraTimeRequest?.authorizer?.username}</p>
																	</>
																}
															</td>
															<td>
																{
																	function () {

																		if (extraTimeRequest.status === "CANCELED") {
																			return (<div class="text-center">
																				<i class="bi bi-x-square-fill" style={{
																					color: "red",
																					fontSize: "28px"
																				}}></i>
																				<p>Cancelado</p>
																			</div>);
																		}

																		// if (extraTimeRequest.status === "AUTH_RH") {
																		// 	return (<div class="text-center">
																		// 		<i class="bi bi-check-square-fill" style={{
																		// 			color: "green",
																		// 			fontSize: "28px"
																		// 		}}></i>
																		// 		<p>Autorizado</p>
																		// 	</div>);
																		// }

																		const userProfile = user.profile;
																		if (userProfile === "" || userProfile == null)
																			return <></>;														


																		if(extraTimeRequest.status === "ACTIVE" || extraTimeRequest.edit) {
																			return <div class="text-center align-items-center">
																				<input type="checkbox" class="form-check-input"
																					onClick={() => onSelectCheckbox(request.employee.id, extraTimeRequest.id)}
																					style={{ width: "25px", height: "25px" }}
																					checked={selected[request.employee.id]?.includes(extraTimeRequest.id)}
																				/>
																			</div>
																		}

																	}()
																}
															</td>
															<td className="report-hidden">
																<div class="d-flex justify-content-end">
																	{getButtons(extraTimeRequest, user, onAuth, onEdit, onCancel)}
																</div>
															</td>
														</tr>
													))
												}
											</tbody>
										</table>
									</div>
								</div>
							</>
						) 
					}
				</div>

			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

		</>

	);
}

export default ListView;