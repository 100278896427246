import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getOffices } from "api/offices";
import { getJobs } from "api/jobs";
import { getDepartments } from "api/departments";
import { getPayrolls } from "api/payrolls";
import { getPayrollPeriods } from "api/payrollPeriods";
import View from "components/crh/extraTimeRequests/Filters.view";

function FormSave({ company, setIsVisible, setFilters, refresh }) {

    const [offices, setOffices] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [payrolls, setPayrolls] = useState([]);
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh)
        setRefreshValue(refresh)

    useEffect(() => {

      getOffices({ company_id: company.id }).then((response) => {
        if (response.errors.length > 0) {
          if (!toast.isActive("toast-offices-eror"))
            toast.error("Error al obtener las sucursales", { toastId: "toast-jobs-eror" })
        } else {
          setOffices(response.data)
        }
      })
  
      getJobs({ company_id: company.id }).then((response) => {
        if (response.errors.length > 0) {
          if (!toast.isActive("toast-jobs-eror"))
            toast.error("Error al obtener los puestos", { toastId: "toast-jobs-eror" })
        } else {
          setJobs(response.data)
        }
      })
  
      getDepartments({ company_id: company.id }).then((response) => {
        if (response.errors.length > 0) {
          if (!toast.isActive("toast-departments-eror"))
            toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
        } else {
          setDepartments(response.data)
        }
      })

      getPayrolls({ company_id: company.id }).then((response) => {
        if (response.errors.length > 0) {
          if (!toast.isActive("toast-payrolls-eror"))
            toast.error("Error al obtener los tipos de nómina", { toastId: "toast-payrolls-eror" })
        } else {
          setPayrolls(response.data)
        }
      })

      getPayrollPeriods({ company_id: company.id }).then((response) => {
        if (response.errors.length > 0) {
          if (!toast.isActive("toast-payrollPeriods-eror"))
            toast.error("Error al obtener los periodos de nómina", { toastId: "toast-payrollPeriods-eror" })
        } else {
          setPayrollPeriods(response.data)
        }
      })
  
    }, [])

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null;

        setFilters(values);
        setIsVisible(false);

    }

    return (
        <View
            offices={offices}
            jobs={jobs}
            departments={departments}
            payrolls={payrolls}
            payrollPeriods={payrollPeriods}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
}

export default FormSave;