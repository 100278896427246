import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { downloadFile } from "utils/files";
import { showResponseMessage } from "utils/message";
import View from "components/crh/employees/BulkLoad.view";

function BulkLoad({ company, title, isVisible, setIsVisible }) {

    const [error, setError] = useState(true);
    const [csvData, setCsvData] = useState([]);
    const [validations, setValidations] = useState([]);
    const inputFileRef = useRef(null);

    const [callValidate, reqValidate] = usePost("/employees/bulk_validate", {
        onCompleted: ({ data }) => {
            setError(false);
            setValidations(data.errors);
        },
        onError: (err) => {
            console.error(err)
            
            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-employees-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de empleados", { toastId: "toast-bulk-employees-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("El archivo se encuentra vacío, por favor rellene datos", { toastId: "toast-upload-error" });
                }
            }

        }
    });

    const [callSave, reqSave] = usePost("/employees/bulk", {
        onCompleted: () => {

            setError(false);
            if (!toast.isActive("toast-save-employees"));
            toast.success("Empleados cargados correctamente", { toastId: "toast-save-employees" });

            setIsVisible(false);
            inputFileRef.current.value = "";
            setCsvData([]);
            setValidations([]);

        },
        onError: (err) => {
            console.error(err)
            setError(true);

            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-employees-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de empleados", { toastId: "toast-bulk-employees-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("Error al cargar los datos", { toastId: "toast-upload-error" });
                }
            }
        }
    });

    const onCancel = () => {
        inputFileRef.current.value = "";
        setCsvData([]);
        setValidations([]);
        setIsVisible(false);
    }

    const onReset = () => {
        inputFileRef.current.value = "";
        setCsvData([]);
        setValidations([]);
    }

    const onDownloadExampleFile = () => {
        const exampleFile = "ID de Empleado, ID en Lector, Nombres, Apellido paterno, Apellido materno, Clave de puesto, Clave de departamento, Clave de regimen," +
            "Clave de contrato, Clave de nomina, Fecha de ingreso (DD/MM/YYYY), Clave de sucursal, Clave de turno, Fecha inicio de turno (DD/MM/YYYY), Estatus," +
            "Fecha de baja (DD/MM/YYYY), Motivo de baja, RFC, CURP, NSS, Correo, Salario diario, Salario mensual, Clave de Jefe directo, Clave de area, Clave de subarea," +
            "Clave de centro de costos, Empresa nomina, Zona Horaria, Planeacion Semanal, Geolocalizacion, Contraseña, Fotografia";
        downloadFile({ data: exampleFile, filename: "template_employees.csv", type: ".csv" });
    }

    const onLoadFile = (data) => {
        if (data.length === 1) {
            callValidate({
                rows: data[0].rows,
                headers: data[0].headers,
                company
            })
            setCsvData(data[0])
        };
    }

    const onSubmit = () => {
        let overwrite = false;
        if (window.confirm("¿Desea que los elementos repetidos en el sistema se sobrescriban?")) {
            overwrite = true;
        }
        callSave({
            rows: csvData.rows,
            headers: csvData.headers,
            overwrite: overwrite,
            company
        })
    }


    return (
        <View
            error={error}
            onDownloadExampleFile={onDownloadExampleFile}
            inputFileRef={inputFileRef}
            onLoadFile={onLoadFile}
            data={csvData}
            validations={validations}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onReset={onReset}
        />
    );
}

export default BulkLoad;