import React from "react";
import HolidayLogsList from "components/crh/holidayLogs/List";
import LoadData from "./LoadData";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';

function HolidayLogsView({
	offices,
	departments,
	jobs,
	payrolls,	
	filters,
	setFilters,
	permissionCreate,
	search,
	onChangeSearch,
	handleKeyUp,
	modalContent,
	addDetailModal,
	theme,
	onLoadDataModal,
	lmIsVisible,
	setLmIsVisible,
	logsRef,
	logs,
	onDownloadCSV,
	onDownloadXlsx,
	permissionPdf,
	permissionExcel,
	user
}) {
	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Estado de cuenta de vacaciones</h1>
						</div>
						<div className="col-sm-auto">
							{
								permissionPdf && (
								<ReactToPrint
									debug={true}
									content={() => logsRef.current}
									trigger={() => (
									<button className="btn bg-danger text-white mx-1">
										<i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
										PDF
									</button>
									)}
								/>
								)
							}

							{
								permissionExcel && (
									<>
										<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
											<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
											Excel
										</button>
										<button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
											<i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
											CSV
										</button>
									</>
								)
							}
							{
								permissionCreate && user?.profile === "RH" && (
									<button type="button" className="btn btn-primary" onClick={onLoadDataModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Cargar saldo
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0 row">
							<div className="col-md-2">
								<div class="input-group input-group-merge input-group-flush">
									<div class="input-group-prepend input-group-text" >
										<i class="bi-search"></i>
									</div>
									<input
										type="search"
										class="form-control"
										placeholder="Buscar empleado"
										onChange={onChangeSearch}
										onKeyUp={handleKeyUp}
									/>
								</div>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control" 
										onChange={e => setFilters({...filters, status: e.target.value})}
										value={filters.status}
									>
										<option value="">Sin selección</option>
										<option value="ACTIVE">Activo</option>
										<option value="LEAVE">Inactivo</option>
									</select>
									<span class="input__label">
										Estatus
									</span>
								</label>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control"
										onChange={e => setFilters({...filters, office_id: e.target.value})}
										value={filters.office_id}
									>
										<option value="">Sin selección</option>
										{
											offices.map((office) =>
												<option value={office.id}>{office.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Sucursal
									</span>
								</label>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control" 
										onChange={e => setFilters({...filters, department_id: e.target.value})}
										value={filters.department_id}
									>
										<option value="">Sin selección</option>
										{
											departments.map((department) =>
												<option value={department.id}>{department.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Departamento
									</span>
								</label>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control"
										onChange={e => setFilters({...filters, job_id: e.target.value})}
										value={filters.job_id}
									>
										<option value="">Sin selección</option>
										{
											jobs.map((job) =>
												<option value={job.id}>{job.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Puesto
									</span>
								</label>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select 
										className="input__field form-control" 
										onChange={e => setFilters({...filters, payroll_id: e.target.value})}
										value={filters.payroll_id}
									>
										<option value="">Sin selección</option>
										{
											payrolls.map((payroll) =>
												<option value={payroll.id}>{payroll.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Tipo de nómina
									</span>
								</label>
							</div>
						</div>
					</div>

					<div ref={logsRef}>

					<div className="report-header">

						<div className="d-flex justify-content-center">
							<div className="logo mt-6">
								{
									user?.company?.logo?.url ? (
										<img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
									) : (
										<img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
									)
								}
							</div>
						</div>

						<div className="text-center">
							<h1 className="mb-3">{user?.company?.name}</h1>
						</div>

						<div className="text-center">
							<h2 className="mb-3">Estado de cuenta de vacaciones</h2>
						</div>

					</div>

							<div className="pagebreak"></div>

						<HolidayLogsList
							logs={logs}
							theme={theme}
							search={search}
							addDetailModal={addDetailModal}
							refresh={Math.random()}
						/>
					</div>

				</div>

			</div>

			<LoadData
				isVisible={lmIsVisible}
				setIsVisible={setLmIsVisible}
			>
				{modalContent}
			</LoadData>

		</>
	);
}

export default HolidayLogsView;