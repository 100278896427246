import React, {useState} from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/admin/enviroments/Form.view";

function FormSave({ setIsVisible,  }) {

    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [uploadedFiles, setUploadedFiles] = useState(null);

    const [callSave, reqSave] = usePost("/companies", {
        onCompleted: () => {

            if(!toast.isActive("company-created"))
                toast.success("Entorno creado correctamente", { toastId: "company-created" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {

            if (err.status === 409) {
                if (!toast.isActive("copmany-error-409")) 
                    toast.error("Ya existe un ambiente con esa clave, por favor ingrese otra", { toastId: "copmany-error-409" });
            } 
            else if (!toast.isActive("company-error")) {
                toast.error("Error interno del servidor", {toastId : "company-error"});
            }
        }
    })

    const onSubmit = (values, {resetForm}) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null
    
        const {key, name, rfc, username, firstname, lastname, password, logo_id} = values
        if(!key || !name || !rfc || !username  || !firstname || !lastname || !password || !logo_id) {
            toast.error("Llene todos los campos obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View 
            onSubmit={onSubmit}
            onCancel={onCancel}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )
}

export default FormSave;